<template>
  <div class="opinion">
    <div class="main_box">
      <p class="title">意见反馈</p>
      <div class="form">
        <el-form ref="form" label-width="100px">
          <el-form-item label="系统评价：">
            <el-rate v-model="star"></el-rate>
          </el-form-item>
          <el-form-item class="box" label="意见反馈：">
            <el-input type="textarea" maxlength="255" id="input-area" placeholder="欢迎您提出宝贵的意见" v-model="opinion"></el-input>
            <span class="tips">{{ opinion.length + '/' }}255字</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="footerBtn">
        <div>
          <span @click="submit">提交</span>
          <span @click="clear">清空</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addFeedback } from '@/api/student'
export default {
  data(){
    return{
      star: 0,
      opinion: ''
    }
  },
  methods: {
    // 清空内容
    clear() {
      this.star = 0
      this.opinion = ''
    },
    // 提交内容
    submit() {
      if (!this.star) {
        this.$message.warning('请最少选择一颗星')
        return false
      }
      if (!this.opinion || this.opinion.length < 10 || this.opinion.length > 255) {
        this.$message.warning('请填写10至255个字的意见')
        return false
      }
      this.$confirm('请核对提交信息，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        addFeedback({ xgr: this.$store.state.userInfo.yhid, cjr: this.$store.state.userInfo.yhid, fkyj: this.opinion, xsid: this.$store.state.userInfo.yhid, xtpj: this.star, zhid: this.$store.state.userInfo.zhid, xsmc: this.$store.state.userInfo.realname, yycdid: ~~sessionStorage.getItem('yycdid') }).then(res => {
          if (res.success) {
            this.clear()
            this.$message.success('提交成功')
          } else {
            this.$message.error(res.resultDesc)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.opinion{
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .main_box{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 2%;
    box-sizing: border-box;
    position: relative;
    .title{
      width: 100%;
      height: 3vw;
      color: #333;
      font-weight: bold;
      font-size: 1.3vw;
      line-height: 3vw;
      text-align: center;
    }
    .footerBtn{
      width: 100%;
      height: calc(100% - 22vw);
      position: relative;
      div{
        display: inline-block;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span{
          display: block;
          float: left;
          width: 7vw;
          height: 2.5vw;
          border-radius: 32px;
          text-align: center;
          line-height: 2.5vw;
          font-size: 1.1vw;
          cursor: pointer;
        }
        span:nth-child(1){
          background: #5990FF;
          color: #fff;
          margin-right: 2vw;
        }
        span:nth-child(2){
          background: #D9E6FF;
          color: #333;
        }
      }
    }
  }
  .box{
    position: relative;
    .tips{
      position: absolute;
      bottom: 0;
      right: 2vw;
    }
  }
}
</style>
<style lang="scss">
.opinion{
  .el-rate__item{
    font-size: 30px;
  }
  .el-form-item{
    margin-bottom: 1vw;
  }
  .el-textarea{
    height: 15vw;
    .el-textarea__inner{
      height: 100%;
    }
  }
  .el-textarea__inner{
    height: 15vw;
    background: #F9F9F9;
  }
  #input-area::-webkit-input-placeholder{
    color: #999999;
    font-size: 1.2vw;
    text-align: center;
    line-height: 12vw;
  }
}
</style>